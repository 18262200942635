import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { map, Observable } from 'rxjs';
import { UserService } from '../../services/user.service';
import { RouterLink } from '@angular/router';
import { Pages } from '../../app.routes';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {

  userService = inject(UserService);

  isUserReady$: Observable<boolean> = this.userService.user$
    .pipe(
      map(user => user.status === 'success'),
    );

  shareText = encodeURIComponent(`Partecipiamo al concorso #CrispySempreEComunque e proviamo a vincere 100 Sony® Playstation® 5 ${environment.appURL}`);

  protected readonly Pages = Pages;

  onShare() {
    gtag('event', `share`,);
  }
}
