@if (isUserReady$ | async) {
  <div class="container">
<!--    <a class="user" [routerLink]="'/'+Pages.gameWeeks">-->
<!--      <img src="assets/img/user.svg" alt="Il mio account">-->
<!--    </a>-->
    <a class="share" href="https://wa.me/?openOutsideMcd=true&text={{shareText}}" (pointerdown)="onShare()">
      <img src="assets/img/share.svg" alt="Condividi">
    </a>
  </div>
}
